export default interface WorkOrderControlState {
  control_id: string;
  scheduling_group_items: Array<SchedulingGroup>;
  prevent_excess_completions: string;
  end_Job_Stock_Transfer_Mode: string;
}

export interface SchedulingGroup {
  scheduling_group: string;
  scheduling_group_description: string;
  scheduling_workcenter_items: Array<WorkCenter>;
}

export interface WorkCenter {
  scheduling_workcenter: string;
}

export enum EndJobStockTransferModes {
  NONE,
  OPTIONAL_NEXT_OPERATION,
  OPTIONAL_OPERATION,
}
